// React imports
import React, { useState, useEffect, useRef } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import SendIcon from "@mui/icons-material/Send";
import Tooltip from "@mui/material/Tooltip";

// Shared state
import { useSharedState } from "./SharedStateContext";

// Packages
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxHeight: "60%", // Adjust the maximum height as needed
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

// PIN Popup component
function PINPopup() {
  const [pin, setPin] = useState("");
  const pinInputRef = useRef(null);
  const {
    isPINPopupOpen,
    setIsPINPopupOpen,
    propertyId,
    setFirstName,
    answers,
    setAnswers,
    setJwtToken,
  } = useSharedState();

  function handlePinChange(e) {
    setPin(e.target.value);
  }

  async function handleQuestionKeyUp(e) {
    if (e.key === "Enter") {
      handlePinSubmit(e);
    }
  }

  async function handlePinSubmit(e) {
    // Handle PIN submission here (e.g., validation, API request)
    // For simplicity, we'll just log the PIN for demonstration
    e.preventDefault();
    if (pin.trim() === "") {
      return;
    }

    // Close the popup
    setIsPINPopupOpen(false);

    try {
      const requestBody = {
        propertyId: propertyId,
        code: pin,
      };

      const response = await axios.post(
        "https://api.staypro.ai/api/login",
        requestBody
      );
      const newJwtToken = response.data.AccessToken;

      // Store the JWT in an HttpOnly cookie
      //document.cookie = `jwt=${newJwtToken}; HttpOnly`;
      setFirstName(response.data.FirstName);
      setJwtToken(newJwtToken);

      const answer = {
        q: null,
        a: `Hello ${response.data.FirstName}! We hope you're enjoying your stay. Now that you're logged in you can get even more questions about the house answered.`,
      };
      setAnswers([...answers, answer]);
    } catch (error) {
      if (
        error.response &&
        error.response.status &&
        error.response.status === 403
      ) {
        const answer = {
          q: null,
          a: `I'm sorry, but this code is not recognized or not yet active.`,
        };
        setAnswers([...answers, answer]);
      } else {
        const answer = { q: null, a: `Something went wrong.` };
        setAnswers([...answers, answer]);
      }
    }
  }

  function togglePopup() {
    setIsPINPopupOpen(false);
  }

  return (
    <div>
      <Modal
        open={isPINPopupOpen}
        onClose={togglePopup}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Have a code provided by the host?
          </Typography>
          <Typography
            id="modal-modal-description"
            variant="body1"
            component="h2"
          >
            Use it to access information for guests currently staying at the
            home. I can recommend local activities, assist in locating items
            around the home, and guide you in their usage, as well as help
            resolve any issues.
          </Typography>
          <br />
          <Grid
            container
            spacing={2}
            padding={1}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={10}>
              <TextField
                id="outlined-basic"
                label="Code"
                variant="outlined"
                fullWidth="true"
                value={pin}
                onChange={handlePinChange}
                onKeyUp={handleQuestionKeyUp}
                inputRef={pinInputRef}
              />
            </Grid>
            <Grid item xs={2}>
              <Tooltip title="Send">
                <Button
                  variant="contained"
                  endIcon={<SendIcon />}
                  onClick={handlePinSubmit}
                  style={{ height: "100%" }}
                ></Button>
              </Tooltip>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}

export default PINPopup;
