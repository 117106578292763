import React, { useState, useEffect, useContext } from "react";
import "./Welcome.css";
import "./LoadingSpinner.css";
import "./ErrorMessage.css";
import "./WelcomeMessages.css";
import axios from "axios";
import { useSharedState } from "./SharedStateContext";
import Typography from "@mui/material/Typography";

function Welcome() {
  const [welcomeMessage, setWelcomeMessage] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const {
    propertyId,
    setPropertyId,
    propertyName,
    setPropertyName,
    isTOSOpen,
    setIsTOSOpen,
    privacyPolicyOpen,
    setIsPrivacyPolicyOpen,
  } = useSharedState();

  useEffect(() => {
    // Fetch welcome messages from the "Welcome" API
    async function fetchWelcomeMessages() {
      try {
        // const queryString = window.location.search;

        // Parse the query string into an object
        //const params = new URLSearchParams(queryString);
        //const pid = params.get("pid");

        const pid = propertyId;
        //setPropertyId(pid);

        const response = await axios.get(
          `https://api.staypro.ai/api/welcome?pid=${pid}`
        );
        setWelcomeMessage(response.data);
        setPropertyName(response.data.PropertyName);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching welcome messages:", error);
      }
    }

    fetchWelcomeMessages();
  }, propertyId);

  const handleOpenTOS = () => {
    setIsTOSOpen(true);
  };

  const handleCloseTOS = () => {
    setIsTOSOpen(false);
  };

  const handleOpenPrivacyPolicy = () => {
    setIsPrivacyPolicyOpen(true);
  };

  const handleClosePrivacyPolicy = () => {
    setIsPrivacyPolicyOpen(false);
  };

  return (
    <div class="welcome">
      {loading ? (
        <div className="loadingSpinner"></div>
      ) : error ? (
        <div className="errorMessage">
          Error loading important announcements
        </div>
      ) : (
        <div className="NEED_CSS">
          <h2>
            {welcomeMessage?.PropertyName
              ? `Welcome to ${welcomeMessage.PropertyName}!`
              : "Welcome!"}
          </h2>
          {welcomeMessage?.Messages.length > 0 && (
            <div>
              <div>Important announcements for today:</div>
              <ul id="welcomeMessages">
                {welcomeMessage?.Messages.map((message, index) => (
                  <li key={index}>{message}</li>
                ))}
              </ul>
            </div>
          )}
          <Typography id="modal-modal-title" variant="body1" component="h2">
            aBBBBRBRBR Curious about our home and amenities? Feel free to ask me
            anything now!
            <br />
            <br />
            And by logging in with your provided code, you unlock more. Whether
            it's finding items around the home, using amenities, receiving local
            activity recommendations, or resolving any issues during your stay,
            I'm here to help.
          </Typography>
          <br />
          <Typography id="modal-modal-title" variant="body1" component="h2">
            Using our website implies agreement with{" "}
            <span
              style={{ color: "blue", cursor: "pointer" }}
              onClick={handleOpenTOS}
            >
              Terms of Service
            </span>
            &nbsp;and&nbsp;
            <span
              style={{ color: "blue", cursor: "pointer" }}
              onClick={handleOpenPrivacyPolicy}
            >
              Privacy Policy
            </span>
            .
          </Typography>
        </div>
      )}
    </div>
  );
}

export default Welcome;
